import * as styles from './Input.module.scss'

import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'gatsby'
import { isEmpty } from 'lodash'

import * as controls from 'components/controls'

import Indicator from 'components/loading/Indicator'

import { InputMode, Attribute } from '../data/types'
import { IDTransformer } from '../data/inferIDTransformer'

import { DropZone } from './DropZone'
import { ListInputConfiguration } from './ListInputConfiguration'
import { SearchConfiguration } from './SearchConfiguration'

interface Props {
  inputMode: InputMode
  setInputMode(value: InputMode): void
  listInput: string
  setListInput(value: string): void
  listInputMode: 'text' | 'csv'
  listInputAttributes: Attribute[]
  searchQuery: string
  setSearchQuery(value: string): void
  globalQueryExtension: string
  setGlobalQueryExtension(value: string): void
  linkInput: string
  setLinkInput(value: string): void
  loading: boolean
  load(): void
  idTransformer: Partial<IDTransformer>
  setIDTransformer(value: Partial<IDTransformer>): void
  overrideIDTransformer: boolean
  setOverrideIDTransformer(value: boolean): void
}

export const Input: React.FC<Props> = (props) => {
  const {
    inputMode,
    setInputMode,
    listInput,
    setListInput,
    listInputMode,
    listInputAttributes,
    searchQuery,
    setSearchQuery,
    globalQueryExtension,
    setGlobalQueryExtension,
    linkInput,
    setLinkInput,
    loading,
    load,
    idTransformer,
    setIDTransformer,
    overrideIDTransformer,
    setOverrideIDTransformer,
  } = props

  const [showInputOptions, setShowInputOptions] = useState(false)

  const hasInput = useMemo(() => {
    if (inputMode === 'list') {
      return !isEmpty(listInput)
    } else if (inputMode === 'search') {
      return !isEmpty(searchQuery)
    } else if (inputMode === 'link') {
      return !isEmpty(linkInput)
    }
  }, [inputMode, linkInput, listInput, searchQuery])

  const onInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && hasInput) {
        load()
      }
    },
    [hasInput, load],
  )

  return (
    <div className={styles.container}>
      <div className={styles.inputOptions}>
        <controls.ControlGroup>
          <controls.SegmentedControl
            segments={{ list: 'List', search: 'Search', link: 'Link' }}
            value={inputMode}
            onChange={(event) => {
              setInputMode(event.currentTarget.value as any)
            }}
          />
          {(inputMode === 'list' || inputMode === 'search') && (
            <controls.SmallButton onClick={() => setShowInputOptions(true)}>
              Options
            </controls.SmallButton>
          )}
        </controls.ControlGroup>
        <controls.ControlGroup>
          {loading && <Indicator size="1em" />}

          <controls.SmallButton onClick={() => load()} disabled={!hasInput}>
            Load Cards! &rarr;
          </controls.SmallButton>
        </controls.ControlGroup>
      </div>

      {inputMode === 'list' && (
        <DropZone setInput={setListInput}>
          <textarea
            value={listInput}
            onChange={(event) => setListInput(event.currentTarget.value)}
            className={styles.inputTextArea}
            spellCheck={false}
          />
          <div className={styles.hint}>
            Paste or drop a list of card names or a CSV.
          </div>
        </DropZone>
      )}

      {inputMode === 'search' && (
        <div>
          <input
            type="text"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
            onKeyPress={onInputKeyPress}
            className={styles.inputField}
            spellCheck={false}
          />
          <div className={styles.hint}>
            Enter a search using{' '}
            <Link to="/articles/searching-with-scryfall-magic-at-your-fingertips/">
              Scryfall&rsquo;s query syntax
            </Link>
            .
          </div>
        </div>
      )}

      {inputMode === 'link' && (
        <div>
          <input
            type="text"
            value={linkInput}
            onChange={(event) => setLinkInput(event.currentTarget.value)}
            onKeyPress={onInputKeyPress}
            className={styles.inputField}
            spellCheck={false}
          />
          <div className={styles.hint}>
            Enter a link to a card list page. Supports{' '}
            <a href="http://scryfall.com/decks">Scryfall Deckbuilder</a> and{' '}
            <a href="https://cubecobra.com">Cube Cobra</a>.
          </div>
        </div>
      )}

      {inputMode === 'list' && (
        <controls.ModalWithHeading
          title="Configure Text Input"
          description="Card details will be fetched from Scryfall based on card name, set, collectors number, or platform IDs automatically. Override to specific columns to use in CSV input."
          presented={showInputOptions}
          dismiss={() => setShowInputOptions(false)}
        >
          <ListInputConfiguration
            listInputMode={listInputMode}
            listInputAttributes={listInputAttributes}
            idTransformer={idTransformer}
            setIDTransformer={setIDTransformer}
            overrideIDTransformer={overrideIDTransformer}
            setOverrideIDTransformer={setOverrideIDTransformer}
            dismiss={() => setShowInputOptions(false)}
          />
        </controls.ModalWithHeading>
      )}

      {inputMode === 'search' && (
        <controls.ModalWithHeading
          title="Configure Scryfall Search Query"
          description=""
          presented={showInputOptions}
          dismiss={() => setShowInputOptions(false)}
        >
          <SearchConfiguration
            globalQueryExtension={globalQueryExtension}
            setGlobalQueryExtension={setGlobalQueryExtension}
            dismiss={() => setShowInputOptions(false)}
          />
        </controls.ModalWithHeading>
      )}
    </div>
  )
}
