import * as styles from './Images.module.scss'

import React from 'react'

import CardImage from 'components/cards/CardImage'

import { RowWithData } from '../data/types'

interface Props {
  rows: RowWithData[]
}

export const Images: React.FC<Props> = (props) => {
  const { rows } = props

  return (
    <div className={styles.container}>
      {rows.map((row, index) =>
        row.card?.name ? (
          <CardImage
            key={`${row.card.name}-${index}`}
            set={row.card?.set}
            id={row.card?.id}
            canFlip
            flipMode="button"
          >
            {row.card.name}
          </CardImage>
        ) : (
          // TODO: Style this error view
          <div className={styles.error} key={index}>
            <div className={styles.errorTitle}>Error Loading Card</div>
          </div>
        ),
      )}
    </div>
  )
}
