import * as styles from './Presets.module.scss'

import React from 'react'

import * as controls from 'components/controls'

import { OutputAttribute } from '../../data/types'
import { outputConfigurationPresets } from '../../data/outputConfigurationPresets'

import { PresetOption } from './PresetOption'

interface Props {
  outputAttributes: OutputAttribute[]
  setOutputAttributes(value: OutputAttribute[]): void
}

export const Presets: React.FC<Props> = (props) => {
  const { outputAttributes, setOutputAttributes } = props

  return (
    <div className={styles.container}>
      <controls.GroupLabel>Choose a Preset</controls.GroupLabel>

      <controls.ControlGroup>
        {outputConfigurationPresets.map((preset) => (
          <PresetOption
            key={preset.name}
            preset={preset}
            outputAttributes={outputAttributes}
            setOutputAttributes={setOutputAttributes}
          />
        ))}
      </controls.ControlGroup>
    </div>
  )
}
