// extracted by mini-css-extract-plugin
export var attribute = "TextConfiguration-module--attribute--3d738";
export var attributes = "TextConfiguration-module--attributes--af258";
export var attributesSheet = "TextConfiguration-module--attributesSheet--8453d";
export var code = "TextConfiguration-module--code--c145b";
export var container = "TextConfiguration-module--container--2ad77";
export var exampleLabel = "TextConfiguration-module--exampleLabel--389ac";
export var exampleOption = "TextConfiguration-module--exampleOption--e6313";
export var exampleOptions = "TextConfiguration-module--exampleOptions--21f4f";
export var exampleSample = "TextConfiguration-module--exampleSample--497aa";
export var heading = "TextConfiguration-module--heading--3fcbf";
export var hint = "TextConfiguration-module--hint--596db";
export var label = "TextConfiguration-module--label--f7134";
export var radioButton = "TextConfiguration-module--radioButton--06bc1";
export var section = "TextConfiguration-module--section--0104a";
export var textInput = "TextConfiguration-module--textInput--feefd";