import * as styles from './CardsTable.module.scss'

import React, { useCallback } from 'react'
import classNames from 'classnames'

import { OutputAttribute, RowWithData, SortMethod } from '../data/types'
import { cardAttributes } from '../data/cardAttributes'

import { CardsTableValue } from './CardsTableValue'

interface Props {
  rows: RowWithData[]
  outputAttributes: OutputAttribute[]
  sortMethod: SortMethod | null
  setSortMethod(value: SortMethod | null): void
}

const columnWidths: Record<string, string> = {
  name: '22em',
  type_line: '20em',
  oracle_text: '24em',
  set_name: '20em',
  keywords: '12em',
  id: '24em',
}

const attributeLabels = [...cardAttributes].reduce((result, attribute) => {
  result[attribute.name] = attribute.shortLabel ?? attribute.label
  return result
}, {} as Record<string, string>)

export const CardsTable: React.FC<Props> = (props) => {
  const { rows, outputAttributes, sortMethod, setSortMethod } = props

  const cycleSort = useCallback(
    (column: OutputAttribute) => {
      if (sortMethod?.key !== column.key) {
        setSortMethod({
          key: column.key,
          source: column.source,
          ascending: true,
        })
      } else if (sortMethod.ascending) {
        setSortMethod({
          key: column.key,
          source: column.source,
          ascending: false,
        })
      } else {
        setSortMethod(null)
      }
    },
    [setSortMethod, sortMethod],
  )

  return (
    <table className={styles.table}>
      <thead className={styles.heading}>
        <tr>
          {outputAttributes.map((attribute, index) => (
            <th key={`${attribute.key}${index}`} className={styles.headingCell}>
              <button
                onClick={() => cycleSort(attribute)}
                className={styles.headingButton}
              >
                {sortMethod?.key === attribute.key ? (
                  sortMethod.ascending ? (
                    <span className={styles.sortIcon}>▲</span>
                  ) : (
                    <span className={styles.sortIcon}>▼</span>
                  )
                ) : null}

                {attributeLabels[attribute.key] ??
                  attribute.label ??
                  attribute.key}
              </button>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, index) => (
          <tr
            key={`${index}${row.card?.name}`}
            className={classNames(styles.row, {
              [styles.notFound]: row.notFound,
            })}
          >
            {outputAttributes.map((attribute) => (
              <td key={attribute.id} className={styles.cell}>
                <div
                  style={
                    columnWidths[attribute.key]
                      ? { width: columnWidths[attribute.key] }
                      : {}
                  }
                >
                  <CardsTableValue attribute={attribute} row={row} />
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
