import * as scryfall from 'utils/scryfall'

const aliases: Record<string, string[]> = {
  '10e': ['10th Edition'],
  '7ed': ['7th Edition'],
  '8ed': ['8th Edition'],
  '9ed': ['9th Edition'],
  amid: ['Art Series: Innistrad: Midnight Hunt'],
  akhm: ['Art Series: Kaldheim'],
  aneo: ['Art Series: Kamigawa: Neon Dynasty'],
  amh2: ['Art Series: Modern Horizons 2'],
  asnc: ['Art Series: Streets of New Capenna'],
  aznr: ['Art Series: Zendikar Rising'],
  cst: ['Coldsnap Theme Deck Reprints'],
  cmd: ['Commander'],
  afc: ['Commander: Adventures in the Forgotten Realms'],
}

function simplifyKey(value: string): string {
  return value.toLocaleLowerCase().replace(/[ _-]/g, '')
}

export async function getSets() {
  const sets = (await scryfall.fetchSets()).map((set) => {
    const setAliases = aliases[set.code] ?? []

    return {
      code: set.code,
      name: set.name,
      aliases: [set.code, set.name, ...setAliases],
    }
  })

  const setAliases = sets.reduce((result, set) => {
    for (const alias of set.aliases) {
      result[simplifyKey(alias)] = set.code
    }
    return result
  }, {} as Record<string, string>)

  const normalizeSetID = (value: string): string => {
    return setAliases[simplifyKey(value)] ?? value
  }

  return { sets, normalizeSetID }
}
