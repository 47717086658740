import * as styles from './ImageCompositeDownloaderModal.module.scss'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import * as controls from 'components/controls'
import Indicator from 'components/loading/Indicator'

import { usePersistentState } from 'utils/usePersistentState'
import { renderAndDownloadImage } from 'utils/canvas-rendering/captureImage'

import { RowWithData } from '../data/types'

import { defaultOptions, ImageGridDownloadOptions } from './data/configuration'
import { renderCardSetImage } from './data/renderCardSetImage'
import { generateGridLayout } from './data/generateGridLayout'
import { generateColumnLayout } from './data/generateColumnLayout'

import { Options } from './Options'

interface Props {
  dismiss(): void
  rows: RowWithData[]
}

export const ImageCompositeDownloaderModal: React.FC<Props> = (props) => {
  const { dismiss, rows } = props

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const [options, setOptions] = usePersistentState<ImageGridDownloadOptions>(
    'list-formatter-image-grid-options',
    defaultOptions,
    (value) => value,
  )

  const [generating, setGenerating] = useState(false)

  const layout = useMemo(() => {
    return options.layout === 'grid'
      ? generateGridLayout(rows, options)
      : generateColumnLayout(rows, options)
  }, [options, rows])

  const download = useCallback(() => {
    setGenerating(true)

    renderAndDownloadImage(
      layout.imageSize.width,
      layout.imageSize.height,
      `cards-${new Date().toISOString().slice(0, 10)}`,
      async (context) => {
        await renderCardSetImage(context, layout, false)
      },
    ).then(() => {
      setGenerating(false)
    })
  }, [layout])

  useEffect(() => {
    if (canvasRef.current != null) {
      renderCardSetImage(canvasRef.current.getContext('2d')!, layout, true)
    }
  }, [layout])

  return (
    <controls.ModalWithHeading
      title="Download Image of All Cards"
      description="Generate an image of all cards in a grid or column layout. Dimensions are in pixels."
      presented={true}
      dismiss={dismiss}
    >
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <div className={styles.options}>
            <Options options={options} setOptions={setOptions} />
          </div>

          <div className={styles.preview}>
            <canvas
              ref={canvasRef}
              width={layout.imageSize.width}
              height={layout.imageSize.height}
              className={styles.previewCanvas}
            />

            <div className={styles.previewDimensions}>
              <div>
                Image Size:{' '}
                {isNaN(layout.imageSize.width) ? (
                  <>&mdash; &times; &mdash;</>
                ) : (
                  <>
                    {layout.imageSize.width.toFixed(0)} &times;{' '}
                    {layout.imageSize.height.toFixed(0)}px
                  </>
                )}
              </div>
              <div>
                Card Size:{' '}
                {isNaN(layout.cardSize.width) ? (
                  <>&mdash; &times; &mdash;</>
                ) : (
                  <>
                    {layout.cardSize.width.toFixed(0)} &times;{' '}
                    {layout.cardSize.height.toFixed(0)}px
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {layout.errors.length > 0 && (
          <div className={styles.errors}>{layout.errors.join(' ')}</div>
        )}

        <controls.ControlGroup alignRight>
          {generating && <Indicator />}
          <controls.SmallButton
            secondary
            onClick={dismiss}
            disabled={generating}
          >
            Cancel
          </controls.SmallButton>

          <controls.SmallButton onClick={download} disabled={generating}>
            Generate Image
          </controls.SmallButton>
        </controls.ControlGroup>
      </div>
    </controls.ModalWithHeading>
  )
}
