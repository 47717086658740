import { cardAttributes } from '../cardAttributes'
import { Attribute } from '../types'

const validArrayAttributes = ['colors', 'color-identity', 'color-indicator']

export const formattableAttributes: Attribute[] = [
  {
    name: 'count',
    label: 'Count',
    slug: 'count',
    type: 'number',
  } as Attribute,
  ...cardAttributes.filter(
    (attribute) =>
      (attribute.type != null &&
        ['string', 'number'].includes(attribute.type)) ||
      validArrayAttributes.includes(attribute.slug),
  ),
]
