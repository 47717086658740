import * as styles from './Archiver.module.scss'

import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'

import * as controls from 'components/controls'

import Indicator from 'components/loading/Indicator'

import { RowWithData } from '../data/types'

import { ImagesArchiveDownloader } from './data/ImagesArchiveDownloader'
import { downloadsForCards } from './data/downloadsForCards'
import { generateInfoFile } from './data/generateInfoFile'
import { ImageSetDownloadOptions } from './data/types'

interface Props {
  dismiss(): void
  rows: RowWithData[]
  options: ImageSetDownloadOptions
}

export const Archiver: React.FC<Props> = (props) => {
  const { dismiss, rows, options } = props

  const { imageType } = options

  const downloaderRef = useRef<ImagesArchiveDownloader | null>(null)

  const [progress, setProgress] = useState(0)
  const [error, setError] = useState(false)

  const { downloads, missingRows } = useMemo(() => {
    return downloadsForCards(rows, options)
  }, [rows, options])

  useEffect(() => {
    const downloader = new ImagesArchiveDownloader(
      downloads,
      `card-images-${new Date().toISOString().slice(0, 10)}`,
      (status) => {
        setProgress(status.progress)
        if (status.error) {
          setError(true)
        } else if (status.complete) {
          dismiss()
        }
      },
      {
        // Disable cache on 'normal' images. These are used in image tags on the
        // site so will be cached without the necessary cross-origin request
        // headers and will fail to load.
        disableCache: imageType === 'normal',
        additionalFiles: [
          {
            content: generateInfoFile(imageType, missingRows),
            fileName: '- Info.text',
          },
        ],
      },
    )

    downloaderRef.current = downloader

    return downloader.cancel
  }, [dismiss, downloads, imageType, missingRows])

  const cancelDownload = useCallback(() => {
    downloaderRef.current?.cancel()
    dismiss()
  }, [dismiss])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {error ? (
          <>There was an error generating the archive.</>
        ) : (
          <>
            <div className={styles.details}>
              <div>Preparing Image Archive</div>
              <div>
                Downloading {progress} / {downloads.length} card images
              </div>
            </div>
            <Indicator />
          </>
        )}

        <controls.SmallButton onClick={cancelDownload} secondary>
          {error ? 'Dismiss' : 'Cancel'}
        </controls.SmallButton>
      </div>
    </div>
  )
}
