import React from 'react'
import { clamp } from 'lodash'

import * as controls from 'components/controls'

import { ImageGridDownloadOptions } from './data/configuration'

interface Props {
  options: ImageGridDownloadOptions
  setOptions(options: ImageGridDownloadOptions): void
}

export const Options: React.FC<Props> = (props) => {
  const { options, setOptions } = props

  return (
    <controls.FormColumn>
      <controls.Label title="Layout">
        <controls.Select
          options={[
            { name: 'grid', label: 'Grid' },
            { name: 'columns', label: 'Columns' },
          ]}
          value={options.layout}
          onChange={(event) =>
            setOptions({
              ...options,
              layout: event.currentTarget.value as any,
            })
          }
        />
      </controls.Label>

      {options.layout === 'grid' && (
        <controls.CheckboxField
          label="Include Both Sides of DFCs"
          checked={options.dfcBacks}
          onChange={(event) => {
            setOptions({
              ...options,
              dfcBacks: event.currentTarget.checked,
            })
          }}
        />
      )}

      {options.layout === 'columns' && (
        <controls.CheckboxField
          label="Skip Empty Columns"
          checked={options.skipEmptyColumns}
          onChange={(event) => {
            setOptions({
              ...options,
              skipEmptyColumns: event.currentTarget.checked,
            })
          }}
        />
      )}

      <controls.Label title="Background Color">
        <controls.ColorInput
          value={options.backgroundColor}
          onChange={(event) =>
            setOptions({
              ...options,
              backgroundColor: event.currentTarget.value,
            })
          }
        />
      </controls.Label>

      <controls.Label title="Columns">
        <controls.NumberInput
          value={options.columns}
          onChange={(event) =>
            setOptions({
              ...options,
              columns: clamp(parseInt(event.currentTarget.value), 1, 100),
            })
          }
        />
      </controls.Label>

      <controls.NumberInputField
        label="Image Width"
        unit="px"
        name="imageWidth"
        value={options.imageWidth}
        onChange={(event) =>
          setOptions({
            ...options,
            imageWidth: clamp(parseInt(event.currentTarget.value), 1, 10000),
          })
        }
      />

      <controls.NumberInputField
        label="Padding"
        hint="Spacing around image edge"
        unit="px"
        name="padding"
        value={options.padding}
        onChange={(event) =>
          setOptions({
            ...options,
            padding: clamp(parseInt(event.currentTarget.value), 0, 10000),
          })
        }
      />

      <controls.NumberInputField
        label="Gutter"
        hint="Spacing between cards"
        unit="px"
        name="gutter"
        value={options.gutter}
        onChange={(event) =>
          setOptions({
            ...options,
            gutter: clamp(parseInt(event.currentTarget.value), 0, 10000),
          })
        }
      />
    </controls.FormColumn>
  )
}
