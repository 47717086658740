import * as scryfall from 'utils/scryfall'

import { IDTransformer } from './inferIDTransformer'
import { fetchCubeCards } from 'src/utils/cube-cobra'
import { fetchScryfallDetails } from './fetchScryfallDetails'
import { InputMode } from './types'
import { sourceForURL } from './sourceForURL'
import { flattenedScryfallCards } from './flattenedScryfallCards'

export async function loadFlattenedCards(
  inputMode: InputMode,
  searchQuery: string,
  linkInput: string,
  listInputRows: Record<string, string | number>[],
  idTransformer: Partial<IDTransformer>,
  normalizeSetID: (value: string) => string,
) {
  const result = await loadCards(
    inputMode,
    searchQuery,
    linkInput,
    listInputRows,
    idTransformer,
    normalizeSetID,
  )

  return result?.map((result) => ({
    ...result,
    card: result.card != null ? flattenedScryfallCards(result.card) : undefined,
  }))
}

export async function loadCards(
  inputMode: InputMode,
  searchQuery: string,
  linkInput: string,
  listInputRows: Record<string, string | number>[],
  idTransformer: Partial<IDTransformer>,
  normalizeSetID: (value: string) => string,
) {
  if (inputMode === 'search') {
    if (searchQuery.length > 2) {
      const result = await scryfall.search(searchQuery, { maxPages: 20 })

      return result.map((card) => ({ card }))
    }
  } else if (inputMode === 'link') {
    const source = sourceForURL(linkInput)

    if (source?.source === 'cube-cobra') {
      const result = await fetchCubeCards(source.id)

      return result?.cards != null ? result.cards.map((card) => ({ card })) : []
    } else if (source?.source === 'scryfall') {
      console.log('Scryfall source')
      const result = await scryfall.fetchDeckCards(source.id)

      return result?.cards != null ? result.cards.map((card) => ({ card })) : []
    }
  } else {
    const result = await fetchScryfallDetails(
      listInputRows as any,
      idTransformer as any,
      normalizeSetID,
    )

    return result
  }
}
