import * as styles from './Help.module.scss'

import React from 'react'
import { Link } from 'gatsby'

export const Help: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>MTG List Formatter by Lucky Paper</h2>

      <p>A swiss-army-knife for Magic card data.</p>

      <p>
        This tool works with lists of MTG cards, automating tasks like
        transforming card lists between formats used by different systems,
        adding card details to spreadsheets, or fetching card images.
      </p>

      <p>
        <strong>Input</strong> a list of cards or fetch a list via a{' '}
        <a href="https://scryfall.com" target="_blank" rel="noreferrer">
          Scryfall
        </a>{' '}
        search or{' '}
        <a href="https://cubecobra.com" target="_blank" rel="noreferrer">
          Cube Cobra
        </a>{' '}
        link. Many common formats are supported.
      </p>

      <p>
        <strong>Output</strong> a highly configurable data set with card details
        fetched from{' '}
        <a href="https://scryfall.com" target="_blank" rel="noreferrer">
          Scryfall
        </a>
        . Formats include CSV, images, data formats, or configurable text.
      </p>

      <hr className={styles.rule} />

      <h2 className={styles.heading}>
        Questions, issues, or feature requests?
      </h2>

      <p>
        If you have any issues, questions, or a use case this tool doesn&rsquo;t{' '}
        <em>quite</em>
        cover, don&rsquo;t hesitate to reach out to me on Bluesky{' '}
        <a
          href="https://bsky.app/profile/anthony.luckypaper.co"
          target="_blank"
          rel="noreferrer"
        >
          @anthony.luckypaper.co
        </a>{' '}
        , find me on Discord, or <Link to="/contact/">contact Lucky Paper</Link>{' '}
        by email.
      </p>

      <hr className={styles.rule} />

      <h2 className={styles.heading}>What is Lucky Paper?</h2>

      <p>
        A few friends making things about Magic including{' '}
        <Link to="/articles/">articles</Link>,{' '}
        <Link to="/resources/cube-map/">data visualizations</Link> a{' '}
        <Link to="/podcast/">podcast</Link>, and{' '}
        <Link to="/resources/">tools like this</Link>. We focus on{' '}
        <Link to="/what-is-a-cube/">Cube</Link> and other sandbox formats.
      </p>
    </div>
  )
}
