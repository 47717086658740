import * as styles from './Layout.module.scss'

import React from 'react'

export const LayoutIcon: React.FC = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      className={styles.svg}
    >
      <defs></defs>
      <rect
        x="2"
        y="2"
        width="12"
        height="12"
        style={{ stroke: '#FFFFFF' }}
        fill="none"
        rx="2"
        ry="2"
      ></rect>
      <path
        d="M 8 2 H 12 A 2 2 0 0 1 14 4 V 12 A 2 2 0 0 1 12 14 H 8 V 2 Z"
        fill="#fff"
      ></path>
    </svg>
  )
}
