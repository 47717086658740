import { kebabCase, mapKeys, snakeCase } from 'lodash'
import * as d3 from 'd3'

import { Attribute } from './types'
import { inferCSV } from './inferCSV'
import { parseCardList } from './parseCardList'

type ListInputMode = 'text' | 'csv'

/**
 * Parse the input text. Infers if the content is a CSV or a list of cards with
 * counts. Returns the result as an array of strings for each row, and an array
 * of column details.
 */
export function parseInput(
  input: string,
): [ListInputMode, Record<string, string | number>[], Attribute[]] {
  if (inferCSV(input)) {
    const result = d3.csvParse(input, (row, index) => {
      return { row: index + 1, ...mapKeys(row, (_, key) => snakeCase(key)) }
    })

    return [
      'csv',
      result,
      [
        ...result.columns.map((column) => ({
          name: snakeCase(column),
          slug: kebabCase(column),
          label: column,
        })),
      ],
    ]
  }

  const { listItems, attributes } = parseCardList(input)

  return ['text', listItems, attributes]
}
