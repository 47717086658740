import yaml from 'yaml'

import { nonBlankString } from 'utils/strings'

import { OutputAttribute, RowWithData } from './types'
import { rowValue } from './rowValue'

function formatValue(attribute: OutputAttribute, row: RowWithData) {
  const { key, source } = attribute

  const value = rowValue(attribute, row)

  if (value == null) {
    return undefined
  } else if (
    source === 'card' &&
    key === 'name' &&
    row.card?.layout === 'adventure'
  ) {
    return row.card.card_faces?.[0]?.name
  } else if (
    key === 'colors' ||
    key === 'color_indicator' ||
    key === 'color_identity'
  ) {
    return (value as string[]).join('')
  }

  return value
}

export function formatYAML(
  rows: RowWithData[],
  outputAttributes: OutputAttribute[],
) {
  const data = rows.map((row) => {
    return outputAttributes.reduce((result, outputAttribute) => {
      const key = nonBlankString(outputAttribute.label) ?? outputAttribute.key

      if (outputAttribute.fillValue != null) {
        result[key] = outputAttribute.fillValue
      } else {
        result[key] = formatValue(outputAttribute, row)
      }

      return result
    }, {} as Record<string, string>)
  })

  return yaml.stringify(data, { keepUndefined: true, nullStr: '' })
}
