import React, { useMemo } from 'react'

import * as controls from 'components/controls'

import { OutputAttribute } from '../../data/types'
import { nonBlankString } from 'src/utils/strings'

interface Props {
  preset: {
    name: string
    attributes: OutputAttribute[]
  }
  outputAttributes: OutputAttribute[]
  setOutputAttributes(value: OutputAttribute[]): void
}

export const PresetOption: React.FC<Props> = (props) => {
  const { preset, outputAttributes, setOutputAttributes } = props

  const selected = useMemo(() => {
    if (preset.attributes.length !== outputAttributes.length) {
      return false
    }

    for (let i = 0; i < preset.attributes.length; i++) {
      const a = preset.attributes[i]
      const b = outputAttributes[i]
      if (
        a.key !== b.key ||
        nonBlankString(a.label) !== nonBlankString(b.label)
      ) {
        return false
      }
    }

    return true
  }, [outputAttributes, preset.attributes])

  return (
    <div>
      <controls.SmallButton
        onClick={() => setOutputAttributes(preset.attributes)}
        secondary={!selected}
      >
        {preset.name}
      </controls.SmallButton>
    </div>
  )
}
