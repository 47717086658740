import * as scryfall from 'utils/scryfall'

import { RowWithData } from './types'

export const exampleCard: RowWithData = {
  input: {
    row: '1',
    name: 'tarmogoyf',
  },
  card: {
    object: 'card',
    id: '69daba76-96e8-4bcc-ab79-2f00189ad8fb',
    oracle_id: '45900b2f-f6a9-4c42-9642-008f3c1cf6dd',
    multiverse_ids: [509600],
    mtgo_id: 86845,
    tcgplayer_id: 233959,
    cardmarket_id: 545241,
    name: 'Tarmogoyf',
    lang: 'en',
    released_at: '2021-03-19',
    uri: 'https://api.scryfall.com/cards/69daba76-96e8-4bcc-ab79-2f00189ad8fb',
    scryfall_uri: 'https://scryfall.com/card/tsr/235/tarmogoyf?utm_source=api',
    layout: 'normal' as scryfall.CardLayout,
    highres_image: true,
    image_status: 'highres_scan',
    image_uris: {
      small:
        'https://cards.scryfall.io/small/front/6/9/69daba76-96e8-4bcc-ab79-2f00189ad8fb.jpg?1619398799',
      normal:
        'https://cards.scryfall.io/normal/front/6/9/69daba76-96e8-4bcc-ab79-2f00189ad8fb.jpg?1619398799',
      large:
        'https://cards.scryfall.io/large/front/6/9/69daba76-96e8-4bcc-ab79-2f00189ad8fb.jpg?1619398799',
      png: 'https://cards.scryfall.io/png/front/6/9/69daba76-96e8-4bcc-ab79-2f00189ad8fb.png?1619398799',
      art_crop:
        'https://cards.scryfall.io/art_crop/front/6/9/69daba76-96e8-4bcc-ab79-2f00189ad8fb.jpg?1619398799',
      border_crop:
        'https://cards.scryfall.io/border_crop/front/6/9/69daba76-96e8-4bcc-ab79-2f00189ad8fb.jpg?1619398799',
    },
    mana_cost: '{1}{G}',
    cmc: 2,
    type_line: 'Creature — Lhurgoyf',
    oracle_text:
      "Tarmogoyf's power is equal to the number of card types among cards in all graveyards and its toughness is equal to that number plus 1.",
    power: '*',
    toughness: '1+*',
    colors: ['G' as scryfall.Color],
    color_identity: ['G' as scryfall.Color],
    keywords: [],
    legalities: {
      standard: 'not_legal',
      future: 'not_legal',
      historic: 'legal',
      gladiator: 'legal',
      pioneer: 'not_legal',
      explorer: 'not_legal',
      modern: 'legal',
      legacy: 'legal',
      pauper: 'not_legal',
      vintage: 'legal',
      penny: 'not_legal',
      commander: 'legal',
      oathbreaker: 'legal',
      brawl: 'not_legal',
      historicbrawl: 'legal',
      alchemy: 'not_legal',
      paupercommander: 'not_legal',
      duel: 'legal',
      oldschool: 'not_legal',
      premodern: 'not_legal',
      predh: 'legal',
    },
    games: ['paper', 'mtgo'],
    reserved: false,
    foil: true,
    nonfoil: true,
    finishes: ['nonfoil', 'foil'],
    oversized: false,
    promo: false,
    reprint: true,
    variation: false,
    set_id: '11e90d1b-0502-43e6-b056-e24836523c13',
    set: 'tsr',
    set_name: 'Time Spiral Remastered',
    set_type: 'masters' as scryfall.SetType,
    set_uri:
      'https://api.scryfall.com/sets/11e90d1b-0502-43e6-b056-e24836523c13',
    set_search_uri:
      'https://api.scryfall.com/cards/search?order=set&q=e%3Atsr&unique=prints',
    scryfall_set_uri: 'https://scryfall.com/sets/tsr?utm_source=api',
    rulings_uri:
      'https://api.scryfall.com/cards/69daba76-96e8-4bcc-ab79-2f00189ad8fb/rulings',
    prints_search_uri:
      'https://api.scryfall.com/cards/search?order=released&q=oracleid%3A45900b2f-f6a9-4c42-9642-008f3c1cf6dd&unique=prints',
    collector_number: '235',
    digital: false,
    rarity: 'mythic' as scryfall.Rarity,
    flavor_text: "What doesn't grow, dies. And what dies grows the tarmogoyf.",
    card_back_id: '0aeebaf5-8c7d-4636-9e82-8c27447861f7',
    artist: 'Justin Murray',
    artist_ids: ['73920db8-778a-4dfa-99b7-99e8219ca0d8'],
    illustration_id: 'abf5b6d9-61e7-47f0-b187-a6bdfec68f58',
    border_color: 'black',
    frame: '2015',
    security_stamp: 'oval',
    full_art: false,
    textless: false,
    booster: true,
    story_spotlight: false,
    edhrec_rank: 11355,
    preview: {
      source: 'Kotaku',
      source_uri:
        'https://www.kotaku.com.au/2021/03/time-spiral-remastered-is-reprinting-one-of-magics-most-versatile-beasts/amp/',
      previewed_at: '2021-03-03',
    },
    prices: {
      usd: '10.71',
      usd_foil: '28.03',
      usd_etched: null,
      eur: '17.94',
      eur_foil: '37.00',
      tix: '2.14',
    },
    related_uris: {
      gatherer:
        'https://gatherer.wizards.com/Pages/Card/Details.aspx?multiverseid=509600',
      tcgplayer_infinite_articles:
        'https://infinite.tcgplayer.com/search?contentMode=article&game=magic&partner=scryfall&q=Tarmogoyf&utm_campaign=affiliate&utm_medium=api&utm_source=scryfall',
      tcgplayer_infinite_decks:
        'https://infinite.tcgplayer.com/search?contentMode=deck&game=magic&partner=scryfall&q=Tarmogoyf&utm_campaign=affiliate&utm_medium=api&utm_source=scryfall',
      edhrec: 'https://edhrec.com/route/?cc=Tarmogoyf',
    },
    purchase_uris: {
      tcgplayer:
        'https://www.tcgplayer.com/product/233959?page=1&utm_campaign=affiliate&utm_medium=api&utm_source=scryfall',
      cardmarket:
        'https://www.cardmarket.com/en/Magic/Products/Search?referrer=scryfall&searchString=Tarmogoyf&utm_campaign=card_prices&utm_medium=text&utm_source=scryfall',
      cardhoarder:
        'https://www.cardhoarder.com/cards/86845?affiliate_id=scryfall&ref=card-profile&utm_campaign=affiliate&utm_medium=card&utm_source=scryfall',
    },
  },
}
