import { CanvasRenderingContext } from 'src/utils/canvas-rendering/canvasDrawing'

/**
 * Create an offscreen canvas and downloads an image of what's drawn in the
 * callback function.
 */
export async function renderAndDownloadImage(
  width: number,
  height: number,
  fileName: string,
  draw: (context: CanvasRenderingContext) => void,
) {
  const offscreen = new OffscreenCanvas(width, height)

  const context = offscreen.getContext('2d')

  if (context == null) {
    throw new Error('Unable to create graphics context')
  }

  await draw(context)

  const blob = await offscreen.convertToBlob()

  downloadBlob(blob, `${fileName}.png`)
}

function downloadBlob(blob: Blob, name = 'file.txt') {
  const blobUrl = URL.createObjectURL(blob)

  const link = document.createElement('a')

  link.href = blobUrl
  link.download = name

  document.body.appendChild(link)

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )

  document.body.removeChild(link)
}
