import * as styles from './TextOutput.module.scss'

import React, { useMemo } from 'react'

import CardHover from 'components/cards/CardHover'

import { RowWithData } from '../data/types'
import { transformerForTemplate } from '../data/formatText'

interface Props {
  rows: RowWithData[]
  rowTemplate: string
}

export const TextOutput: React.FC<Props> = (props) => {
  const { rows, rowTemplate } = props

  const transformer = useMemo(() => {
    return transformerForTemplate(rowTemplate)
  }, [rowTemplate])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {rows.map((row, index) => (
          <div key={index}>
            {row.card?.name != null ? (
              <CardHover
                card={row.card?.name}
                set={row.card?.set}
                id={row.card?.id}
              >
                {transformer(row)}
              </CardHover>
            ) : (
              <>{transformer(row)}</>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
