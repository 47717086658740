import * as styles from './Options.module.scss'

import React, { useCallback } from 'react'

import * as controls from 'components/controls'

import { ImageSetDownloadOptions } from './data/types'

const typeOptions = [
  { label: 'Small', name: 'small' },
  { label: 'Normal', name: 'normal' },
  { label: 'Large', name: 'large' },
  { label: '.png', name: 'png' },
  { label: 'Art Crop', name: 'art_crop' },
  { label: 'Border Crop', name: 'border_crop' },
]

const fileNameOptions = [
  { label: 'card-name.jpg', name: 'slug' },
  { label: 'set-number-name.jpg', name: 'set-number-slug' },
  { label: 'scryfall-id.jpg', name: 'scryfall-id' },
]

interface Props {
  options: ImageSetDownloadOptions
  setOptions(options: ImageSetDownloadOptions): void
}

export const Options: React.FC<Props> = (props) => {
  const { options, setOptions } = props

  const onChangeOption = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setOptions({
        ...options,
        [event.currentTarget.name]: event.currentTarget.value,
      })
    },
    [options, setOptions],
  )

  return (
    <div className={styles.container}>
      <controls.Label title="Image Format">
        <controls.Select
          value={options.imageType}
          onChange={onChangeOption}
          options={typeOptions}
          name="imageType"
        />
      </controls.Label>
      <controls.Label title="Name Images By">
        <controls.Select
          value={options.fileNameType}
          onChange={onChangeOption}
          options={fileNameOptions}
          name="fileNameType"
        />
      </controls.Label>
    </div>
  )
}
