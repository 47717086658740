export interface ImageGridDownloadOptions {
  layout: 'grid' | 'columns'
  columns: number
  imageWidth: number
  padding: number
  gutter: number
  backgroundColor: string
  dfcBacks: boolean
  skipEmptyColumns: boolean
}

export const defaultOptions: ImageGridDownloadOptions = {
  layout: 'grid',
  columns: 6,
  imageWidth: 1255,
  padding: 15,
  gutter: 5,
  backgroundColor: '#ffffff',
  dfcBacks: true,
  skipEmptyColumns: false,
}

export const cardAspectRatio = 88 / 63
export const maxImageDimension = 20000
export const maxImages = 400
