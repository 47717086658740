import * as d3 from 'd3'

import { nonBlankString } from 'utils/strings'

import { RowWithData, OutputAttribute } from './types'
import { rowValue } from './rowValue'

function formatValue(attribute: OutputAttribute, row: RowWithData) {
  const { key, source } = attribute

  const value = rowValue(attribute, row)

  if (value == null) {
    return ''
  } else if (
    source === 'card' &&
    key === 'name' &&
    row.card?.layout === 'adventure'
  ) {
    return row.card.card_faces?.[0]?.name
  } else if (
    key === 'colors' ||
    key === 'color_indicator' ||
    key === 'color_identity'
  ) {
    return (value as string[]).join('')
  } else if (
    // Serialize non-array objects and arrays of objects as JSON. This is far
    // from ideal, but is a quick way to make the data at least potentially
    // accessible.
    (Array.isArray(value) &&
      value.length > 0 &&
      typeof value[0] === 'object') ||
    (!Array.isArray(value) && typeof value === 'object')
  ) {
    return JSON.stringify(value)
  }

  return value
}

export function formatCSV(
  rows: RowWithData[],
  outputAttributes: OutputAttribute[],
  includeHeadings: boolean,
): string {
  const headings = outputAttributes.map((outputAttribute) => {
    return nonBlankString(outputAttribute.label) ?? outputAttribute.key
  })

  const dataRows = rows.map((row) => {
    return outputAttributes.map((outputAttribute) => {
      if (outputAttribute.fillValue != null) {
        return outputAttribute.fillValue
      }

      if (outputAttribute.key != null) {
        return formatValue(outputAttribute, row)
      }

      return ''
    })
  })

  return d3.csvFormatRows(includeHeadings ? [headings, ...dataRows] : dataRows)
}
