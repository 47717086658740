import * as styles from './CardsTableValue.module.scss'

import React from 'react'

import CardHover from 'components/cards/CardHover'
import ManaCost from 'components/ManaCost'
import CardSymbol, { CardSymbolType } from 'components/CardSymbol'

import { OutputAttribute, RowWithData } from '../data/types'
import { rowValue } from '../data/rowValue'

interface Props {
  attribute: OutputAttribute
  row: RowWithData
}

export const CardsTableValue: React.FC<Props> = (props) => {
  const { attribute, row } = props

  if (attribute.fillValue != null && attribute.fillValue.length > 0) {
    return <>{attribute.fillValue}</>
  }

  const value = rowValue(attribute, row)

  if (
    value == null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return <>&nbsp;</>
  }

  // Attribute Specific Formatters

  if (attribute.key === 'error') {
    return value ? 'Error' : ''
  }

  if (['colors', 'color_identity', 'color_indicator'].includes(attribute.key)) {
    return (
      <div className={styles.manaCost}>
        {(value as CardSymbolType[]).map((symbol, index) => (
          <CardSymbol key={index}>{symbol}</CardSymbol>
        ))}
      </div>
    )
  }

  if (attribute.key === 'mana_cost') {
    const cost =
      row.card?.card_faces != null && row.card.card_faces.length > 0
        ? row.card.card_faces[0].mana_cost
        : value

    return (
      <div className={styles.manaCost}>
        <ManaCost block>{cost}</ManaCost>
      </div>
    )
  }

  if (attribute.key === 'oracle_text') {
    return <div className={styles.oracleText}>{value}</div>
  }

  if (attribute.key === 'set' && typeof value === 'string') {
    return <span>{value.toUpperCase()}</span>
  }

  if (attribute.key === 'rarity' && typeof value === 'string') {
    return <span>{value.slice(0, 1).toUpperCase()}</span>
  }

  if (
    attribute.key === 'name' &&
    typeof value === 'string' &&
    row.card != null
  ) {
    return (
      <CardHover card={value} set={row.card.set} id={row.card.id}>
        {value}
      </CardHover>
    )
  }

  if (typeof value === 'string' && value.startsWith('http')) {
    return (
      <a href={value} target="_blank" rel="noreferrer">
        {value}
      </a>
    )
  }

  // General Attribute Type Formatters

  if (typeof value === 'boolean') {
    return value ? 'true' : 'false'
  }

  if (typeof value === 'number') {
    return value.toString()
  }

  if (
    Array.isArray(value) &&
    (typeof value[0] === 'string' || typeof value[0] === 'number')
  ) {
    return value.join(', ')
  }

  if (typeof value === 'object') {
    return JSON.stringify(value)
  }

  return value
}
