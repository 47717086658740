import * as styles from './ImageArchiveDownloadModal.module.scss'

import React, { useState } from 'react'

import * as scryfall from 'utils/scryfall'
import * as controls from 'components/controls'

import { usePersistentState } from 'utils/usePersistentState'

import { RowWithData } from '../data/types'
import { fileNameTypes, ImageSetDownloadOptions } from './data/types'

import { Archiver } from './Archiver'
import { Options } from './Options'

const defaultOptions: ImageSetDownloadOptions = {
  imageType: 'normal' as scryfall.ImageType,
  fileNameType: 'slug',
}

interface Props {
  dismiss(): void
  rows: RowWithData[]
}

export const ImageArchiveDownloadModal: React.FC<Props> = (props) => {
  const { dismiss, rows } = props

  const [options, setOptions] = usePersistentState<ImageSetDownloadOptions>(
    'list-formatter-image-options',
    defaultOptions,
    (value) => {
      return scryfall.imageTypes.includes(value.imageType) &&
        fileNameTypes.includes(value.fileNameType)
        ? value
        : defaultOptions
    },
  )

  const [downloading, setDownloading] = useState(false)

  return downloading ? (
    <Archiver dismiss={dismiss} rows={rows} options={options} />
  ) : (
    <controls.ModalWithHeading
      title="Download Card Images"
      presented={true}
      dismiss={dismiss}
    >
      <div className={styles.container}>
        <Options options={options} setOptions={setOptions} />
        <controls.ControlGroup>
          <controls.SmallButton secondary onClick={dismiss}>
            Cancel
          </controls.SmallButton>
          <controls.SmallButton onClick={() => setDownloading(true)}>
            Download Zip
          </controls.SmallButton>
        </controls.ControlGroup>
      </div>
    </controls.ModalWithHeading>
  )
}
