import * as styles from './MetaControls.module.scss'

import React, { useState } from 'react'

import * as controls from 'components/controls'

import { Help } from './Help'
import { LayoutIcon } from './icons/Layout'

interface Props {
  reset(): void
  stackedLayout: boolean
  setStackedLayout(value: boolean): void
}

export const MetaControls: React.FC<Props> = (props) => {
  const { reset, stackedLayout, setStackedLayout } = props

  const [showHelp, setShowHelp] = useState(false)

  return (
    <>
      <controls.ControlGroup>
        <div className={styles.layoutButton}>
          <controls.SmallButton
            onClick={() => setStackedLayout(!stackedLayout)}
            title="Swap Layout"
          >
            <div
              className={styles.layoutIcon}
              style={stackedLayout ? { transform: 'rotate(90deg)' } : {}}
            >
              <LayoutIcon />
            </div>
          </controls.SmallButton>
        </div>
        <controls.SmallButton onClick={reset}>Reset</controls.SmallButton>
        <span>-</span>
        <controls.SmallButton
          onClick={() => {
            setShowHelp(true)
          }}
        >
          Help & Feedback
        </controls.SmallButton>
      </controls.ControlGroup>

      <controls.ModalWithHeading
        presented={showHelp}
        dismiss={() => setShowHelp(false)}
      >
        <Help />
      </controls.ModalWithHeading>
    </>
  )
}
