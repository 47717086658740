import { useEffect, useState } from 'react'

import { getSets } from './sets'

const defaultNormalizeSedID = (value: string) => value

// Function to normalize set names and IDs. Used to map names to set codes as
// well as handle common set name aliases used by other services. This is
// initially just a pass through until set data is loaded.
export function useNormalizeSetID() {
  const [normalizeSetID, setNormalizeSetID] = useState<
    (value: string) => string
  >(defaultNormalizeSedID)

  useEffect(() => {
    getSets().then((result) => {
      setNormalizeSetID(() => result.normalizeSetID)
    })
  }, [])

  return normalizeSetID
}
