import * as scryfall from 'utils/scryfall'

import { Attribute } from './types'

/**
 * Cards in Scryfall requests can be uniquely identified by a set of one or two
 * attributes of a card, including name, set, number, and various platform IDs.
 *
 * This defines the attributes and set of attributes that can be used to request
 * cards, methods for mapping from arbitrary dictionary objects to identifying
 * attributes, and inferring how to map objects to those attributes.
 */

export const idComponents = {
  id: {
    name: 'id',
    label: 'ID',
    aliases: ['id', 'scryfall_id', 'scryfallid'],
  },
  mtgo_id: {
    name: 'mtgo_id',
    label: 'MTGO ID',
    aliases: ['mtgoid'],
    normalize: (value: string) => {
      return parseInt(value)
    },
  },
  multiverse_id: {
    name: 'multiverse_id',
    label: 'Multiverse ID',
    aliases: ['multiverseid'],
    normalize: (value: string) => {
      return parseInt(value)
    },
  },
  oracle_id: {
    name: 'oracle_id',
    label: 'Oracle ID',
    aliases: ['oracleid'],
  },
  illustration_id: {
    name: 'illustration_id',
    label: 'Illustration ID',
    aliases: ['illustrationid'],
  },
  name: {
    name: 'name',
    label: 'Name',
    aliases: ['name', 'card', 'cardname'],
    normalize: (value: string) => {
      return value.split('//')[0].trim()
    },
  },
  collector_number: {
    name: 'collector_number',
    label: 'Collector Number',
    aliases: ['collectornumber', 'number'],
  },
  set: {
    name: 'set',
    label: 'Set',
    aliases: ['set', 'setcode'],
  },
}

export const scryfallIdentifiers = [
  {
    name: 'scryfall_id',
    label: 'Scryfall ID',
    attributes: [idComponents.id],
  },
  {
    name: 'set_collector_number',
    label: 'Set & Collector Number',
    attributes: [idComponents.collector_number, idComponents.set],
  },
  {
    name: 'set_card_name',
    label: 'Set & Card Name',
    attributes: [idComponents.name, idComponents.set],
  },
  {
    name: 'card_name',
    label: 'Card Name',
    attributes: [idComponents.name],
  },
  {
    name: 'mtgo_id',
    label: 'MTGO ID',
    attributes: [idComponents.mtgo_id],
  },
  {
    name: 'multiverse_id',
    label: 'Multiverse ID',
    attributes: [idComponents.multiverse_id],
  },
  {
    name: 'oracle_id',
    label: 'Oracle ID',
    attributes: [idComponents.oracle_id],
  },
  {
    name: 'illustration_id',
    label: 'Illustration ID',
    attributes: [idComponents.illustration_id],
  },
]

function simplifyKey(value: string): string {
  return value.toLocaleLowerCase().replace(/[ _-]/g, '')
}

export interface IDTransformer {
  label: string
  name: string
  attributes: {
    name: string
    label: string
    aliases: string[]
    normalize?(value: string): string | number
  }[]
  sourceAttributes: Record<string, Attribute | null>
}

/**
 * Returns the identifier to use for fetching a row from Scryfall given a row
 * and transformer.
 */
export function idsFromRows(
  rows: Record<string, string>[],
  transformer: IDTransformer,
  normalizeSetID: (value: string) => string,
): scryfall.CardID[] {
  const idFromRow = (row: Record<string, string>) => {
    return transformer.attributes.reduce((result, attribute) => {
      const sourceAttribute = transformer.sourceAttributes[attribute.name]
      if (sourceAttribute == null) {
        throw new Error(`No source attribute named ${attribute.name} found`)
      }
      let value: string | number = row[sourceAttribute.name]

      if (attribute.normalize != null) {
        value = attribute.normalize(value)
      } else if (attribute.name === 'set') {
        value = normalizeSetID(value)
      }

      if (value) {
        result[attribute.name] = value
      }
      return result
    }, {} as any)
  }

  return rows.map(idFromRow)
}

/**
 * Infer the ID transformer that should be used for an input set of rows with
 * the given attributes.
 */
export function inferIDTransformer(
  attributes: Attribute[],
): IDTransformer | null {
  for (const identifier of scryfallIdentifiers) {
    const sourceAttributes = identifier.attributes.reduce(
      (result, attribute) => {
        result[attribute.name] =
          attributes.find((sourceAttribute) =>
            attribute.aliases.includes(simplifyKey(sourceAttribute.name)),
          ) ?? null
        return result
      },
      {} as Record<string, Attribute | null>,
    )

    if (Object.values(sourceAttributes).every((a) => a != null)) {
      return {
        ...identifier,
        sourceAttributes,
      }
    }
  }

  return null
}
