import * as styles from './YAMLPreview.module.scss'

import React, { useMemo } from 'react'

import { RowWithData, OutputAttribute } from '../data/types'
import { formatYAML } from '../data/formatYAML'

interface Props {
  data: RowWithData[]
  outputAttributes: OutputAttribute[]
}

export const YAMLPreview: React.FC<Props> = (props) => {
  const { data, outputAttributes } = props

  const yaml = useMemo(() => {
    return formatYAML(data, outputAttributes)
  }, [data, outputAttributes])

  return (
    <div className={styles.container}>
      <div className={styles.content}>{yaml}</div>
    </div>
  )
}
