import React from 'react'

export const HorizontalBars: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="16px"
      height="16px"
    >
      <path d="m25,9.01H5c-.55,0-1-.45-1-1s.45-1,1-1h20c.55,0,1,.45,1,1s-.45,1-1,1Z" />
      <path d="m25,16H5c-.55,0-1-.45-1-1s.45-1,1-1h20c.55,0,1,.45,1,1s-.45,1-1,1Z" />
      <path d="m25,22.99H5c-.55,0-1-.45-1-1s.45-1,1-1h20c.55,0,1,.45,1,1s-.45,1-1,1Z" />
    </svg>
  )
}
