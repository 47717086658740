import React from 'react'

export const Remove: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="16px"
      height="16px"
    >
      <path d="m7.93,23.07c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41l14.14-14.14c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41l-14.14,14.14c-.2.2-.45.29-.71.29Z" />
      <path d="m22.07,23.07c-.26,0-.51-.1-.71-.29L7.22,8.64c-.39-.39-.39-1.02,0-1.41s1.02-.39,1.41,0l14.14,14.14c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
    </svg>
  )
}
