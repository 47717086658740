export function slugForAttribute(source: string | undefined, name: string) {
  return source != null ? `${source}___${name}` : name
}

export function attributeForSlug(
  slug: string,
): ['card' | 'input' | undefined, string] {
  const components = slug.split(/___/)
  return components.length == 2
    ? (components as ['card' | 'input', string])
    : [undefined, components[0]]
}
