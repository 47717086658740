import * as d3 from 'd3'

import { idComponents } from './inferIDTransformer'

const idComponentAliases = Object.values(idComponents).flatMap(
  (component) => component.aliases,
)

/**
 * Infer if a string value is formatted as a CSV using some very rudimentary
 * heuristics.
 *
 * If the first line includes a comma, the first 10 lines are parsed. Returns
 * true if all rows include the same number of values.
 */
export function inferCSV(value: string): boolean {
  const lines = value.split('\n')

  // If there aren't at least two lines, infer not a CSV
  if (lines.length < 2) {
    return false
  }

  // If every element in the heading matches an inferred attribute (even if
  // there are no commas) infer a CSV.
  if (
    lines[0].split(',').every((heading) => {
      return idComponentAliases.includes(heading.toLowerCase())
    })
  ) {
    return true
  }

  // If the first line has no commas, infer not a CSV
  if (!/,/.test(lines[0])) {
    return false
  }

  // Attempt to parse as a CSV. Infer it is if the first 8 lines all include
  // the same number of components as the heading
  const csvRows = d3.csvParseRows(lines.slice(0, 10).join('\n'))
  return csvRows.slice(1, 9).every((row) => row.length === csvRows[0].length)
}
