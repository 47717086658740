import {
  CanvasRenderingContext,
  drawRoundedRect,
} from 'src/utils/canvas-rendering/canvasDrawing'
import { loadRemoteImage } from './loadRemoteImage'

export async function renderCardImage(
  context: CanvasRenderingContext,
  imageURL: string,
  x: number,
  y: number,
  width: number,
  height: number,
) {
  context.save()

  const cornerRadius = height * 0.04

  drawRoundedRect(context, x, y, width, height, cornerRadius)

  context.clip()

  const image = await loadRemoteImage(imageURL, width)

  context.drawImage(image, x, y, width, height)
  context.restore()
}
