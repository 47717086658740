import {
  CanvasRenderingContext,
  drawRoundedRect,
} from 'src/utils/canvas-rendering/canvasDrawing'

import { renderCardImage } from './renderCardImage'
import { CompositeCardImageLayout } from './CardLayout'

export async function renderCardSetImage(
  context: CanvasRenderingContext,
  layout: CompositeCardImageLayout,
  preview: boolean,
) {
  context.fillStyle = layout.backgroundColor
  context.fillRect(0, 0, layout.imageSize.width, layout.imageSize.height)

  for (const card of layout.cards) {
    if (preview) {
      drawRoundedRect(
        context,
        card.x,
        card.y,
        card.width,
        card.height,
        card.height * 0.04,
      )
      context.fillStyle = '#BE359C'
      context.fill()
      context.strokeStyle = layout.backgroundColor
      context.lineWidth = 4
      context.stroke()
    } else {
      await renderCardImage(
        context,
        card.url,
        card.x,
        card.y,
        card.width,
        card.height,
      )
    }
  }

  if (!preview) {
    context.save()

    context.textBaseline = 'top'

    for (const text of layout.text) {
      context.font = `${text.size}px Chap sans-serif`
      context.textAlign = 'right'

      context.shadowColor = 'black'
      context.shadowBlur = 7
      context.lineWidth = 5
      context.strokeText(text.text, text.x, text.y)

      context.fillStyle = '#ffffff'
      context.fillText(text.text, text.x, text.y)
    }

    context.restore()
  }
}
