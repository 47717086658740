export const notNan = (value: number): number | null => {
  return isNaN(value) ? null : value
}

export const toInt = (value?: string | null): number | null => {
  return value == null ? null : notNan(parseInt(value))
}

export const toFloat = (value?: string | null): number | null => {
  return value == null ? null : notNan(parseFloat(value))
}
