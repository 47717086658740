import { sortBy } from 'lodash'

import * as mtg from 'utils/mtg'

import { RowWithData, SortMethod } from './types'
import { rowValue } from './rowValue'

function orderForColors(colors: string[]): number {
  return (
    mtg.sort.colorIdentityOrder[
      sortBy(colors, (c) => mtg.sort.colorOrder[c.toLowerCase()])
        .join('')
        .toLocaleLowerCase()
    ] ?? 100
  )
}

function parseSemiNumeric(value: string) {
  if (!value) {
    return -10
  }
  const int = parseInt(value)
  return isNaN(int) ? -1 : int
}

const sortFunctions: Record<string, (row: any) => any> = {
  color_identity: orderForColors,
  colors: orderForColors,
  color_indicator: orderForColors,
  rarity: (rarity) => mtg.sort.raritiesOrder[rarity],
  collector_number: parseSemiNumeric,
  power: parseSemiNumeric,
  toughness: parseSemiNumeric,
  loyalty: parseSemiNumeric,
  mtgo_id: parseSemiNumeric,
  mtgo_foil_id: parseSemiNumeric,
  tcgplayer_id: parseSemiNumeric,
  cardmarket_id: parseSemiNumeric,
  edhrec_rank: parseSemiNumeric,
  penny_rank: parseSemiNumeric,
}

/**
 * Sorts a set of rows based on the given property and direction. Keys matching
 * some card features, like colors and rarities, are treated specially.
 */
export function sortRows(
  rows: RowWithData[],
  sort?: SortMethod,
): RowWithData[] {
  if (!sort) {
    return rows
  }

  const sortFunction = sortFunctions[sort.key] ?? ((value) => value)

  const sorted = sortBy(rows, (row) => sortFunction(rowValue(sort, row)))

  return sort.ascending ? sorted : [...sorted.reverse()]
}
