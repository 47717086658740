import { RowWithData } from './types'

export function rowValue(
  attribute: { key: string; source?: 'input' | 'card' },
  row: RowWithData,
) {
  const { key, source } = attribute

  if (source === 'card') {
    return (row.card as Record<string, any>)?.[key]
  }

  if (source === 'input') {
    return (row.input as Record<string, any>)?.[key]
  }

  return (
    (row.input as Record<string, any>)?.[key] ??
    (row.card as Record<string, any>)?.[key]
  )
}
