import { trim } from 'lodash'

import { Attribute } from './types'

const genericPattern =
  /^((?<count>\d+)?x? )?(?<name>\S.*?)( [([](?<set>[a-zA-Z0-9]{2,4})[)\]])?$/
const arenaPattern =
  /^(?<count>\d+) (?<name>.+) \((?<set>[a-zA-Z0-9]{2,5})\) (?<collector_number>\d+\w?)$/

function addAttribute(attributes: Attribute[], value: Attribute) {
  if (!attributes.find((attribute) => attribute.name === value.name)) {
    return [...attributes, value]
  }
  return attributes
}

function parseCount(value: string) {
  const parsed = parseInt(value)
  if (isNaN(parsed)) {
    return 1
  }
  return parsed
}

/**
 * Parses a string as a plain text list of cards. The file is interpreted as
 * a list of card names, 1 name per-line, with an optional count before the
 * name.
 *
 * Returns the list of cards which include a row number, name, and count if
 * detected.
 */
export function parseCardList(input: string) {
  let attributes = [{ name: 'name', label: 'Name', slug: 'name' }]

  const listItems = input
    .split('\n')
    .map((line) => trim(line))
    .filter((line) => line.length > 0)
    .map((line, index) => {
      const arenaMatch = line.match(arenaPattern)?.groups

      if (arenaMatch) {
        attributes = addAttribute(attributes, {
          name: 'count',
          slug: 'count',
          label: 'Count',
        })
        attributes = addAttribute(attributes, {
          name: 'set',
          slug: 'set',
          label: 'Set',
        })
        attributes = addAttribute(attributes, {
          name: 'collector_number',
          slug: 'collector-number',
          label: 'Collector Number',
        })

        return {
          row: index + 1,
          count: parseCount(arenaMatch.count),
          name: arenaMatch.name,
          set: arenaMatch.set,
          collector_number: arenaMatch.collector_number,
        }
      }

      const match = line.match(genericPattern)?.groups

      if (match == null) {
        return {
          row: index + 1,
          name: line,
        }
      }

      if (match.count) {
        attributes = addAttribute(attributes, {
          name: 'count',
          slug: 'count',
          label: 'Count',
        })
      }

      if (match.set) {
        attributes = addAttribute(attributes, {
          name: 'set',
          slug: 'set',
          label: 'Set',
        })
      }

      return {
        row: index + 1,
        count: parseCount(match.count),
        name: match.name,
        set: match.set,
      }
    }) as any

  return {
    listItems,
    attributes,
  }
}
