import * as styles from './CSVPreview.module.scss'

import React, { useMemo } from 'react'

import { formatCSV } from '../data/formatCSV'
import { RowWithData, OutputAttribute } from '../data/types'

interface Props {
  data: RowWithData[]
  outputAttributes: OutputAttribute[]
  includeHeadings: boolean
}

export const CSVPreview: React.FC<Props> = (props) => {
  const { data, outputAttributes, includeHeadings } = props

  const csv = useMemo(() => {
    return formatCSV(data, outputAttributes, includeHeadings)
  }, [data, outputAttributes, includeHeadings])

  return (
    <div className={styles.container}>
      <div className={styles.content}>{csv}</div>
    </div>
  )
}
