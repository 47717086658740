import { transformerForTemplate } from './transformerForTemplate'

import { RowWithData } from '../types'

/**
 * Transform a single row with a given template.
 */
export function transformRow(row: RowWithData, template: string): string {
  return transformerForTemplate(template)(row)
}

/**
 * Format a set of cards with a template string. Returns an array of formatted
 * lines for each card.
 */
export function transformRows(rows: RowWithData[], template: string): string[] {
  const transformer = transformerForTemplate(template)

  return rows.map(transformer)
}

/**
 * Format a set of cards with a template string into a single, formatted piece
 * of text.
 */
export function formatText(rows: RowWithData[], template: string): string {
  return transformRows(rows, template).join('\n')
}
