export function downloadText(content: string, filename: string) {
  const csvContent = `data:text/plain;charset=utf-8,${content}`

  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', `${filename}.txt`)
  document.body.appendChild(link)

  link.click()
}
