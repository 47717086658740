const sources = [
  {
    name: 'cube-cobra' as const,
    pattern: /cubecobra.com\/.*\/(?<id>[a-zA-Z0-9-_]+?)($|\?)/i,
  },
  {
    name: 'scryfall' as const,
    pattern: /scryfall.com.*?\/decks\/(?<id>[a-zA-Z0-9-_]+?)($|\/\?)/i,
  },
]

export function sourceForURL(url: string) {
  const trimmedURL = url.trim()
  for (const source of sources) {
    const result = trimmedURL.match(source.pattern)
    if (result?.groups?.id != null) {
      return {
        source: source.name,
        id: result?.groups?.id,
      }
    }
  }

  return null
}
