import { exampleCard } from '../exampleCard'
import { transformRow } from './transform'

export const formatExamples = [
  {
    template: '{name}',
    label: 'Card Names',
  },
  {
    template: '{count} {name}',
    label: 'Card Names with Count',
  },
  {
    template: '{count}x {name} [{set-code}]',
    label: 'Card Names with Count and Set Code',
  },
  {
    template: '- [{name}]({scryfall-url})',
    label: 'Markdown Links',
  },
  {
    template: '<a href="{scryfall-url}">{name}</a>',
    label: 'HTML Links',
  },
].map((example) => {
  return {
    ...example,
    sample: transformRow(exampleCard, example.template),
  }
})

export const defaultFormatTemplate = formatExamples[0].template
