import JSZip from 'jszip'
import { saveAs } from 'file-saver'

interface Download {
  fileName: string
  url: string
}

interface Status {
  progress: number
  complete: boolean
  error?: boolean
}

/**
 * Package a set of images from urls into a zip archive and download it. The
 * callback is fired with status updates on download progress.
 */
export class ImagesArchiveDownloader {
  downloads: Download[]
  callback: (status: Status) => void
  disableCache = false

  fileName: string
  zip: JSZip
  total: number
  currentIndex = 0

  abortController = new AbortController()
  cancelled = false

  constructor(
    downloads: Download[],
    fileName: string,
    callback: (status: Status) => void,
    options: {
      disableCache?: boolean
      additionalFiles?: { content: string; fileName: string }[]
    },
  ) {
    this.downloads = downloads
    this.fileName = fileName
    this.disableCache = options.disableCache ?? false
    this.callback = callback

    this.total = downloads.length
    this.zip = new JSZip()

    if (options.additionalFiles) {
      for (const file of options.additionalFiles) {
        this.zip.file(file.fileName, file.content)
      }
    }

    if (this.downloads.length > 0) {
      this.downloadNext()
    } else {
      this.callback({ progress: this.currentIndex, complete: true })
    }
  }

  cancel: () => void = () => {
    this.cancelled = true
    this.abortController.abort()
  }

  downloadNext() {
    if (this.cancelled) {
      return
    }

    const download = this.downloads[this.currentIndex]

    const cacheOptions: Partial<RequestInit> = this.disableCache
      ? { cache: 'no-cache' }
      : {}

    fetch(download.url, {
      signal: this.abortController.signal,
      headers: {
        Origin: window.location.origin,
      },
      ...cacheOptions,
    })
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        this.zip.file(download.fileName, data, { binary: true })

        this.currentIndex++

        this.callback({ progress: this.currentIndex, complete: false })

        if (this.currentIndex === this.total) {
          this.zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `${this.fileName}.zip`)
            this.callback({ progress: this.currentIndex, complete: true })
          })
        } else {
          this.downloadNext()
        }
      })
      .catch(() => {
        this.callback({
          progress: this.currentIndex,
          complete: false,
          error: true,
        })
      })
  }
}
