import { compact } from 'lodash'

import { Attribute, RowWithData } from '../types'

import { formattableAttributes } from './formattableAttributes'

const objectPattern = /\{[\w-]+?\}/g

const attributesBySlug = formattableAttributes.reduce((result, attribute) => {
  result[attribute.slug] = attribute
  return result
}, {} as Record<string, Attribute>)

// Value transformers to control how particular attributes are formatted. Most
// are just used as-is.
const attributeTransformers: Record<string, (row: RowWithData) => string> = {
  count: (row: RowWithData) => {
    const value = row.input?.count
    return value == null ? '1' : value
  },
  name: (row: RowWithData) => {
    if (row.card == null) {
      return ''
    }
    return (
      (row.card.layout === 'adventure'
        ? row.card.card_faces?.[0]?.name
        : row.card.name) ?? ''
    )
  },
  colors: (row: RowWithData) => {
    if (row.card?.colors == null) {
      return ''
    }
    return row.card.colors.join('')
  },
  'color-identity': (row: RowWithData) => {
    if (row.card == null) {
      return ''
    }
    return row.card.color_identity.join('')
  },
  'color-indicator': (row: RowWithData) => {
    if (row.card?.color_indicator == null) {
      return ''
    }
    return row.card.color_indicator.join('')
  },
  'oracle-text': (row: RowWithData) => {
    if (row.card?.oracle_text != null) {
      return row.card.oracle_text
    }
    if (row.card?.card_faces != null) {
      return row.card.card_faces.map((face) => face.oracle_text).join('\n//\n')
    }
    return ''
  },
}

function genericTransformer(attribute: { name: string }) {
  return (row: RowWithData) =>
    (row.card as any)?.[attribute.name] ?? (row.input as any)?.[attribute.name]
}

/**
 * Given a template like "{count}x {name} [{setCode}]", returns a function that
 * takes a card object and interpolates card attributes into braced keywords.
 */
export function transformerForTemplate(template: string) {
  const matches = []

  let match: RegExpExecArray | null = null
  while ((match = objectPattern.exec(template)) != null) {
    matches.push(match)
  }

  // Array of objects representing the template variables to be replaced by card
  // attributes.
  const replacements = compact(
    matches.map((match) => {
      const attribute = attributesBySlug[match[0].slice(1, match[0].length - 1)]

      if (!attribute) {
        return null
      }

      return {
        index: match.index,
        length: match[0].length,
        attribute,
        transformer:
          attributeTransformers[attribute.slug] ??
          genericTransformer(attribute),
      }
    }),
  )

  // An array of pieces of text between replaced, template variables.
  const textSlices = replacements
    .reduce(
      (result, replacement) => {
        result[result.length - 1].push(replacement.index)
        result.push([replacement.index + replacement.length])
        return result
      },
      [[0]],
    )
    .map((slice) => template.slice(...slice))

  // Transformer function
  return (card: RowWithData): string => {
    return replacements.reduce((result, replacement, index) => {
      return result
        .concat(replacement.transformer(card))
        .concat(textSlices[index + 1])
    }, textSlices[0])
  }
}
