import * as scryfall from 'utils/scryfall'

export function flattenedScryfallCards(card: scryfall.Card) {
  console.log('flatten')
  return {
    ...card,
    front_face_name: card.card_faces?.[0]?.name ?? card.name,
    price_usd: card.prices.usd,
    price_usd_foil: card.prices.usd_foil,
    price_usd_etched: card.prices.usd_etched,
    price_eur: card.prices.eur,
    price_eur_foil: card.prices.eur_foil,
    price_tix: card.prices.tix,
  }
}

export type FlattenedScryfallCard = ReturnType<typeof flattenedScryfallCards>
