import { clamp, compact } from 'lodash'

import { RowWithData } from '../../data/types'

import {
  ImageGridDownloadOptions,
  cardAspectRatio,
  maxImageDimension,
  maxImages,
} from './configuration'
import { CompositeCardImageLayout } from './CardLayout'
import { notNan } from 'src/utils/typeConversions'

export function generateGridLayout(
  rows: RowWithData[],
  options: ImageGridDownloadOptions,
): CompositeCardImageLayout {
  const { columns, backgroundColor } = options

  const errors = []

  const imageWidth = clamp(options.imageWidth, 16, maxImageDimension)
  const padding = clamp(notNan(options.padding) ?? 0, 0, options.imageWidth / 2)
  const gutter = clamp(
    notNan(options.gutter) ?? 0,
    0,
    (options.imageWidth - padding * 2) / (columns - 1),
  )

  const allImageURLs = compact(
    rows.flatMap((row) => {
      if (row.card == null) {
        return []
      }

      const count =
        row.input?.count != null ? parseInt(row.input.count) : undefined

      if (row.card.card_faces != null) {
        return row.card.card_faces
          .slice(0, options.dfcBacks ? undefined : 1)
          .map((face, index) => {
            if (face.image_uris?.normal == null) {
              return null
            }

            return {
              imageURL: face.image_uris.normal,
              count: index == 0 ? count : undefined,
            }
          })
      }

      if (row.card?.image_uris?.normal == null) {
        return []
      }

      return [{ imageURL: row.card.image_uris.normal, count }]
    }),
  )

  const imageURLs = allImageURLs.slice(0, maxImages)

  if (allImageURLs.length > imageURLs.length) {
    errors.push(`A maximum of ${maxImages} will be rendered.`)
  }

  const columnWidth =
    (imageWidth - padding * 2 - gutter * (columns - 1)) / columns
  const rowHeight = columnWidth * cardAspectRatio
  const rowCount = Math.ceil(imageURLs.length / columns)
  let imageHeight = rowCount * rowHeight + padding * 2 + gutter * (rowCount - 1)

  imageHeight = Math.ceil(imageHeight)

  if (imageHeight > maxImageDimension) {
    errors.push('Image is too large and will be clipped.')
    imageHeight = maxImageDimension
  }

  const cards = [] as CompositeCardImageLayout['cards']
  const text = []

  for (const [index, imageURL] of imageURLs.entries()) {
    const column = index % columns
    const row = Math.floor(index / columns)

    const x = column * (columnWidth + gutter) + padding
    const y = row * (rowHeight + gutter) + padding

    cards.push({
      url: imageURL.imageURL,
      x,
      y,
      width: columnWidth,
      height: rowHeight,
    })

    if (imageURL.count != null && imageURL.count > 1) {
      text.push({
        text: imageURL.count.toString(),
        x: x + columnWidth * 0.88,
        y: y + rowHeight * 0.4,
        size: columnWidth * 0.18,
      })
    }
  }

  return {
    imageSize: {
      width: imageWidth,
      height: imageHeight,
    },
    cardSize: {
      width: columnWidth,
      height: rowHeight,
    },
    backgroundColor,
    cards,
    text,
    errors,
  }
}
