import { isEqual } from 'lodash'

import * as scryfall from 'utils/scryfall'

import { idsFromRows, IDTransformer } from './inferIDTransformer'
import { RowWithData } from './types'

export async function fetchScryfallDetails(
  inputRows: Record<string, string>[],
  idTransformer: IDTransformer,
  normalizeSetID: (value: string) => string,
): Promise<RowWithData[]> {
  const identifiers = idsFromRows(inputRows, idTransformer, normalizeSetID)

  return scryfall
    .fetchCollection(identifiers)
    .then(({ cards, notFound, error }) => {
      if (error != null) {
        return inputRows.map((input) => ({
          input: input,
          notFound: true,
        }))
      }

      let cardsIndex = 0
      let notFoundIndex = 0

      return inputRows.map((input, index) => {
        if (
          notFound != null &&
          isEqual(notFound[notFoundIndex], identifiers[index])
        ) {
          notFoundIndex++
          return {
            input: input,
            notFound: true,
          }
        } else {
          const card = cards[cardsIndex]
          cardsIndex++
          return {
            input: input,
            card: card,
          }
        }
      })
    })
}
