export type CanvasRenderingContext =
  | OffscreenCanvasRenderingContext2D
  | CanvasRenderingContext2D

export function drawRoundedRect(
  context: CanvasRenderingContext,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) {
  context.beginPath()

  context.moveTo(x + radius, y)
  context.lineTo(x + width - radius, y)
  context.arcTo(x + width, y, x + width, y + radius, radius)
  context.lineTo(x + width, y + height - radius)
  context.arcTo(x + width, y + height, x + width - radius, y + height, radius)
  context.lineTo(x + radius, y + height)
  context.arcTo(x, y + height, x, y + height - radius, radius)
  context.lineTo(x, y + radius)
  context.arcTo(x, y, x + radius, y, radius)

  context.closePath()
}
