/**
 * Load a remote image from a url and returns an HTML image object which can be
 * rendered to a canvas.
 */
export async function loadRemoteImage(
  url: string,
  width: number,
): Promise<HTMLImageElement> {
  // Scryfall requires the origin header to be set for CORS so use fetch rather
  // than creating an image object and assigning a src.
  const response = await fetch(url, {
    headers: {
      Origin: window.location.origin,
    },
    cache: 'no-cache',
  })
  const blob = await response.blob()

  return new Promise((resolve) => {
    const img = new Image()
    img.addEventListener('load', () => {
      resolve(img)
    })
    img.width = width
    img.src = URL.createObjectURL(blob)
  })
}
